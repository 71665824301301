@import '_colors'

.calendar
  border-collapse: collapse

  thead
    background-color: #5a5a5a
    color: #fff
    margin-bottom: 3px
    border-bottom: 2px solid #fff

    th
      padding: 10px 3px
      font-weight: normal

  td
    text-align: center
    padding: 8px
    cursor: pointer
    border: 1px solid hsla(0,0%,73%,.13)
    min-width: 15px

  .month-name
    font-weight: 700
    text-align: left
    cursor: default
    border-left: none

  .next-month, 
  .prev-month,
  .week-separator
    background: $grey-color

  .CalendarDay

    &__selected,
    &__selected:hover,
    &__selected:focus
      background: $primary-color

    span
      color: $chip-bg-danger

.bolder
  font-weight: bold

.DayPickerNavigation 
  display: flex
  align-items: center
  justify-content: space-between

  .DayPickerNavigation_button
    flex-grow: 1

    .month-btn      
      cursor: pointer
      user-select: none
      text-align: center
      width: 100%
      height: 40px
      border: 1px solid rgb(228, 231, 231)
      background: rgb(255, 255, 255)
      font-size: 0

      &:disabled
        cursor: default

      &_prev
        position: relative
        display: flex
        align-items: center
        justify-content: center
        
        &::after
          content: ""
          margin-top: 10px
          border-top: 3px solid $black-color
          border-left: 3px solid $black-color
          display: block
          width: 20px
          height: 20px
          transform: rotate(45deg)

        &:disabled
          cursor: default

          &::after
            border-top: 3px solid $grey-color
            border-left: 3px solid $grey-color

      &_next
        position: relative
        display: flex
        align-items: center
        justify-content: center

        &::after
          content: ""
          margin-bottom: 10px
          border-bottom: 3px solid $black-color
          border-right: 3px solid $black-color
          display: block
          width: 20px
          height: 20px
          transform: rotate(45deg)

        &:disabled
          cursor: default

          &::after
            border-right: 3px solid $grey-color
            border-bottom: 3px solid $grey-color