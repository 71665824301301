body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "SFUIText",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  font-size: 17px !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  text-decoration: none !important;
  border: none !important;
  -webkit-transition: all .3s ease-in-out !important;
  -o-transition: all .3s ease-in-out !important;
  transition: all .3s ease-in-out !important;
  background-color: #0089d0 !important;
  color: #FFF !important;
  border-radius: 2px !important;
  height: 50px !important;
  line-height: 50px;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.button:hover {
  background-color: #333 !important;
}

.button_small:hover {
  background-color: #333 !important;
}

.button_small {
  font-size: 13px !important;
  font-weight: 300 !important;
  cursor: pointer !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  text-decoration: none !important;
  border: none !important;
  -webkit-transition: all .3s ease-in-out !important;
  -o-transition: all .3s ease-in-out !important;
  transition: all .3s ease-in-out !important;
  color: #FFF !important;
  border-radius: 2px !important;
  height: 33px !important;
  line-height: 30px;
  margin: 0px auto 0;
}

.button_regular_color {
  background-color: #0089d0 !important;
}

.button_red_color {
  background-color: #d02e10 !important;
}

.button_green_color {
  background-color: #249e36 !important;
}

.button_grey_color {
  background-color: #555 !important;
}

.paper {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  padding-top: 15px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #DDD !important;
  box-shadow: none !important;
  background-color: #FFF !important;
  border-radius: 0px !important;
}

.paper_list:hover {
  background-color: #EEE !important;
}

.title {
  font-weight: 700 !important;
  font-size: 30px !important;
  color: #333 !important;
  margin-bottom: 15px !important;
  margin-top: 20px !important;
}

.no_internet {
  background-color: #d02e10 !important;
}

.textfield_green {
  color: #00830d !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.paper_list {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  padding-top: 15px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #DDD !important;
  box-shadow: none !important;
  background-color: #FFF !important;
  border-radius: 0px !important;
}

.bg_drawer {
  background-color: #282c34 !important;
}

.load_logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -70px 0 0 -130px;
}

@media only screen and (max-width: 600px) {
    .load_logo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 142px;
        height: 40px;
        margin: -70px 0 0 -90px;
    }
}

.gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: #F9F9F9;
}
.gooey .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 15px;
    background: #ffbb26;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite;
}
.gooey .dots {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    animation: dots 2.8s infinite;
}
.gooey .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    background: #ffbb26;
    border-radius: 50%;
}
@-moz-keyframes dot {
    50% { transform: translateX(96px); }
}
@-webkit-keyframes dot {
    50% { transform: translateX(96px); }
}
@-o-keyframes dot {
    50% { transform: translateX(96px); }
}
@keyframes dot {
    50% { transform: translateX(96px); }
}
@-moz-keyframes dots {
    50% { transform: translateX(-31px); }
}
@-webkit-keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}
@-o-keyframes dots {
    50% { transform: translateX(-31px); }
}
@keyframes dots {
    50% { transform: translateX(-31px); }
}
